<template>
    <UISearchDropdown
        v-model="selected"
        class="settings-region-dropdown"
        :default-item="selectedItem"
        :items="items"
        :placeholder="translate('search.region')"
        :search-index="[`title`, `tags`]"
        @selected="setRegion"
    >
        <template #label>
            <span class="sr-only">
                {{ translate('settings.regio.title') }}
            </span>
        </template>

        <template #button-content>
            <span v-if="selectedItem">
                <UIFlag class="mr-2 flag-dropshadow" aria-hidden="true" :code="selectedItem.code"></UIFlag>
                {{ selectedItem.title }}
            </span>
        </template>

        <template #popup-label>
            <div>{{ translate('settings.regio.select') }}</div>
        </template>

        <template #option-content="{ item }">
            <div class="item">
                <UIFlag class="mr-2 flag-dropshadow" aria-hidden="true" :code="item.code"></UIFlag>
                <span class="option-title">{{ item.title }}</span>
            </div>
        </template>
    </UISearchDropdown>
</template>
<script>
import { UISearchDropdown, UIFlag } from '@dundle/ui/components';

import SettingsMixin from '~/mixins/SettingsMixin';
import TranslationMultipleLanguagesMixin from '~/mixins/TranslationMultipleLanguagesMixin';

export default {
    components: {
        UISearchDropdown,
        UIFlag,
    },
    mixins: [SettingsMixin, TranslationMultipleLanguagesMixin],

    props: {
        defaultItem: {
            type: String,
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected: undefined,
        };
    },
    computed: {
        selectedItem() {
            // need this, because detectedRegion is undefined at load
            if (this.selected === undefined) return this.items.find(item => item.code === this.defaultItem);
            return this.selected;
        },

        items() {
            return this.options.map(item => {
                return { ...item, value: item.code };
            });
        },
    },
    methods: {
        setRegion(item) {
            this.$emit('change', item);
        },
        emitBlur(e) {
            this.$emit('blur', e);
        },
    },
};
</script>
<style lang="scss" scoped>
.settings-region-dropdown {
    color: $color-primary-dark;
    text-align: left;
}
</style>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/search.json"></i18n>
<i18n src="@dundle/locale/data/settings.json"></i18n>
<i18n></i18n>
