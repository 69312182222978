<template>
    <!-- need the click.native to cancel the button click of parent -->
    <!-- need a show to be able to focus on active -->
    <UIPopup
        v-if="popupIsActive"
        class="settings-redirection-modal"
        align="center"
        role="dialog"
        aria-labelledby="description"
        data-prevent-popup-blur="true"
        @click.native.stop.prevent
    >
        <template slot="content">
            <div class="popup-content">
                <p id="description" class="small mr-3">
                    {{
                        translateIn(preferredLanguage, 'popupText', {
                            currentRegion: translateIn(preferredLanguage, `country.${$locale.regio.toUpperCase()}`),
                        })
                    }}
                </p>

                <div class="d-flex align-items-stretch">
                    <div class="flex-grow-1 mr-1">
                        <SettingsRegionDropdown
                            ref="dropdown"
                            :default-item="detectedRegion"
                            :options="regionSet"
                            @change="setSelectedRegion"
                        />
                    </div>
                    <div>
                        <UIButton
                            variant="primary"
                            class="min-width"
                            data-prevent-popup-blur="true"
                            @click.prevent.stop="confirmSettings"
                            >{{ translateIn(preferredLanguage, 'button.confirm') }}</UIButton
                        >
                    </div>
                </div>
            </div>
            <!-- at the end because else it falls behind text -->
            <div role="button" class="close-button" aria-label="close" @click.prevent.stop="hidePopup">
                <UIIcon :name="['fal', 'times']" alt="cancel" />
            </div>
        </template>
    </UIPopup>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { UIButton, UIPopup, UIIcon } from '@dundle/ui/components';
import SettingsRegionDropdown from './SettingsRegionDropdown';
import SettingsMixin from '~/mixins/SettingsMixin';
import TranslationMultipleLanguagesMixin from '~/mixins/TranslationMultipleLanguagesMixin';

export default {
    components: {
        UIIcon,
        UIButton,
        UIPopup,
        SettingsRegionDropdown,
    },
    mixins: [SettingsMixin, TranslationMultipleLanguagesMixin],

    computed: {
        ...mapGetters({
            activePopup: 'ui/activePopup',
            detectedRegion: 'locale/detectedRegion',
        }),

        popupIsActive() {
            return this.filesAreLoaded && this.activePopup && this.activePopup.name === 'settings-redirection';
        },

        defaultLanguage() {
            const a = this.$locale.getDefaultLanguageOfRegion(this.detectedRegion);
            return a;
        },

        preferredInternationalLanguage() {
            const internationalLanguages = this.$locale.getInternationalLanguagesOfRegion(this.detectedRegion);
            return this.$locale.getPreferredLanguageOf(internationalLanguages);
        },

        preferredNativeLanguage() {
            const nativeLanguages = this.$locale.getNativeLanguagesOfRegion(this.detectedRegion);
            return this.$locale.getPreferredLanguageOf(nativeLanguages);
        },

        preferredLanguage() {
            // functions in computed throw errors when detectedRegion is undefined
            if (this.detectedRegion)
                return this.preferredNativeLanguage || this.preferredInternationalLanguage || this.defaultLanguage;
            return undefined;
        },
    },

    watch: {
        // need a watcher on detectedRegion because preferredLanguage cannot work without detectedRegion
        async preferredLanguage(newRegion, old) {
            await this.fetchTranslationsForLanguage(this.preferredLanguage);
        },
        // need a watcher on active to be able to set focus to the button when it's visible
        popupIsActive(newBool) {
            if (newBool === true) {
                this.$nextTick(() => this.$refs.dropdown.$children[0].$refs.toggleButton.focus());
            }
        },
    },

    async created() {
        // added for watcher not working with slow network
        if (this.preferredLanguage) {
            await this.fetchTranslationsForLanguage(this.preferredLanguage);
        }
    },

    methods: {
        ...mapActions({
            hidePopup: 'ui/hidePopup',
        }),

        setSelectedRegion(selected) {
            // on dropdown change
            this.setRegion(selected);

            const code = selected.code;
            const defaultLanguage = this.$locale.getDefaultLanguageOfRegion(code);
            const nativeLanguages = this.$locale.getNativeLanguagesOfRegion(code);
            const preferredNativeLanguage = this.$locale.getPreferredLanguageOf(nativeLanguages);
            const language = preferredNativeLanguage || defaultLanguage;

            this.setLanguage(language);
            this.saveSettings();
            this.hidePopup();
        },

        confirmSettings() {
            this.hidePopup();
            // on button click: save region and instant save settings
            this.setRegion(this.detectedRegion);
            this.setLanguage(this.preferredLanguage);
            this.saveSettings();
        },
    },
};
</script>

<style lang="scss" scoped>
.settings-redirection-modal {
    top: 80%;

    ::v-deep .aligner > .content {
        background-color: rgba(48, 57, 63, 0.95);
        @media screen and (max-width: 767.9px) {
            position: fixed;
            left: 0.5rem !important;
            right: 1rem;
            width: 96%;
            top: $x-menu-bar-height + 0.2rem;
        }
    }
    @media screen and (max-width: 768px) {
        ::v-deep .notch:before {
            z-index: 0;
        }
    }

    ::v-deep .notch {
        opacity: 0.95;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 1rem 1rem 1rem;
        border-color: transparent transparent #30393f transparent;
        top: -1rem;
        &:before {
            display: none;
        }
    }
}
.popup-content {
    position: relative;
    width: 100%;
    color: $color-primary-light;
    @media screen and (min-width: 768px) {
        width: 21rem;
    }
}
.ui-button.min-width {
    min-width: 7rem;
}
.close-button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    color: $color-primary-light;

    &:hover {
        cursor: pointer;
    }
}
</style>

<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/settings.json"></i18n>
<i18n src="@dundle/locale/data/buttons.json"></i18n>
<i18n></i18n>
