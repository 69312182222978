import TranslationMixin from './TranslationMixin';

export default {
    extends: TranslationMixin,

    data() {
        return {
            filesByLanguage: {},
            filesAreLoaded: false,
        };
    },

    methods: {
        async fetchTranslationsForLanguage(language) {
            this.filesAreLoaded = false;
            const file = await this.$api.translations.getAllTranslations(language);
            this.$set(this.filesByLanguage, language, file);
            this.filesAreLoaded = true;

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },

        async fetchTranslationsForKey({ language, key }) {
            this.filesAreLoaded = false;
            const file = await this.$api.translations.getTranslationsByKey({ language, key });
            if (!this.filesByLanguage[language]) this.$set(this.filesByLanguage, language, {});
            if (!this.filesByLanguage[language][key]) this.$set(this.filesByLanguage[language], key, {});

            const fileKey = key;
            // Vue.$set overwrites the already loaded translations for language, so cannot set the whole property to the file
            for (const [key, value] of Object.entries(file)) {
                this.$set(this.filesByLanguage[language][fileKey], key, value);
            }
            this.filesAreLoaded = true;

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
    },
};
