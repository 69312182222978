<template>
    <div class="x-dropdown" :class="classes" @mousedown.stop.prevent>
        <div class="notch"></div>
        <div class="aligner">
            <div class="content">
                <slot name="title"></slot>
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            default: 'center',
        },
    },

    computed: {
        classes() {
            return ['align-' + this.align];
        },
    },

    methods: {
        close() {
            if (document.activeElement && document.activeElement.blur) {
                document.activeElement.blur();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.x-dropdown {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    line-height: initial;

    .content {
        position: absolute;
        padding: 1rem;
        border-radius: 3px;

        background: $color-card-bg;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

        .title {
            font-size: 1.3rem;
            margin-top: 0rem;
            color: $color-primary-dark;
            font-weight: 700;
        }
    }

    &.align-center .aligner {
        left: 50%;

        & > .content {
            left: -12rem;
        }
    }

    &.align-right .aligner {
        right: 0;

        & > .content {
            right: 0;
        }
    }

    &.align-center {
        .notch {
            left: 25%;
        }
    }

    &.align-right {
        .notch {
            left: 48%;
        }
    }
    &.align-left {
        .notch {
            left: 20%;
        }
    }

    &.align-left .aligner {
        left: 0;

        & > .content {
            left: -50%;
            .notch {
                left: 20%;
            }
        }
    }

    .notch {
        position: absolute;

        &:before {
            content: '';
            position: absolute;
            height: 1rem;
            width: 1rem;
            top: -0.6rem;
            left: -0.5rem;
            border-left: 1px solid $color-grey-3;
            border-top: 1px solid $color-grey-3;
            background: $color-card-bg;
            transform: rotate(45deg);
            z-index: 1;
        }
    }
}
</style>
